<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <img src="https://i.imgur.com/cvYZF3I.png" />
          <h1>500 - internal server error</h1>
          <p>This is awkward... You broke the website!</p>
          <p>Sometimes this is caused by discord, sometimes logging out and in fixes it.</p>
          <b-button variant="primary" block to="/">Take me to a working page instead, thank you!</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page500",
  data: function() {
    return {
      hovering: true
    };
  }
};
</script>